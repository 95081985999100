
/* .texto__contacto__titulo{
    padding: 20px;
} */
.color__error{
    color: red;

}

.titulo__contacto__h1{
    font-weight: 700;
    color: rgb(19, 19, 19);
    letter-spacing: -1.7px;
    line-height: 52px;
    font-size: 4rem;
    margin-bottom: 40px;
}
.parrafo__contacto{
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
    color: black;
    text-decoration: none;
}

.info__text__contacto{
    padding: 20px;
}

.h2__contacto__detalle{
    font-weight: 700;
    color: rgb(19, 19, 19);
    letter-spacing: -1.7px;
    line-height: 52px;
    font-size: 3.2rem;
    margin-bottom: 40px;
    line-height: 64px;
    letter-spacing: -2px;
}
.oficina__contacto{
    margin-bottom: 15px;
}

.oficina__contacto__t{
    margin-top: 30px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 35px;
    color: rgb(75, 75, 75);
    text-decoration: none;
}

.mail__contacto__texto{
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
    color: rgb(75, 75, 75);
    text-decoration: none;
}

.boton__ver__mapa__contacto{
    background-color: transparent;
    color: hsl(225,13%,12%);
    padding: 0px;
    display: flex;
    min-width: fit-content;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out 0s;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    border: none;
    cursor: pointer;
}

.span__boton__contacto__flecha{
    margin-left: 1rem;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out 0s;
}

.form__contacto__section{
    padding: 20px;
}

.gcivou {
    margin-bottom: 2.75rem;
    position: relative;
}

.hNoFDp {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 24px;
    color: hsl(0, 0%, 5%);
    text-decoration: none;
}
.gcivou input, .gcivou textarea {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-image: initial;
    border-bottom: 2px solid hsl(0, 0%, 0%);;
    width: 100%;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 22px;
    letter-spacing: -0.3125px;
    padding: 0px 16px 2px 32px;
    transition-property: border, padding;
    transition-duration: 0.15s;
    transition-timing-function: ease-in;
    color: hsl(225,13%,12%);;
}

/* boton */
.cughdu {
    display: grid;
    place-items: center;
    padding: 0px;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-top: -50px;
}
.jWFJdE {
    background-color: black;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in 0s;
}

.labelcontacto{
    margin: 20px;
}

@media screen and (min-width: 1024px){
    .foto__text__contacto{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 150px 150px 150px;
    }
    .div__foto__contacto{
        grid-column: 1/4;
        grid-row: 1/4;
        
    }
    .div__foto__contacto img{
        height: 100%;
        object-fit: cover;
    }
    .texto__contacto__titulo{
        grid-column: 2/4;
        grid-row: 2/4;
        background-color: rgba(255, 255, 255, 0.601);
        display: flex;
        align-items: center;
        text-align: center;
    }

    .info__text__contacto{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* text-align: center; */
        gap: 20px;
        padding-left: 35px;
    }


    /* .mapa__contacto__section{
        padding: 20px;
    } */

    .form__contacto__section{
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 35px;
    }
    .h2__contacto__detalle2{
        width: 30%;
        
    }
    .form__contacto__todo{
        width: 60%;
    }
    


}

