.main_chatbot{
    padding: 1rem;
}
.divchatbot  {
    background-color: white;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0.25rem; 
    max-width: 32rem;  
    margin: auto;  
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.mensajes_chatbot{
    display: flex;
flex-direction: column;
gap: 1rem;
height: 300px;
overflow-y: auto;
}

.mensaje_chatbot{
    padding: 1rem;
max-width: 80%;
color: white;
background-color: #1F2937;
text-align: left;
align-self: flex-start;
border-radius: 1rem;
border-bottom-left-radius: 0;
}
.mensaje_chatbot_usuario{
background-color: #6fabff;
text-align: right;
align-self: flex-end;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 0;
}

.form_chatbot{
    display: flex;
    align-items: center;
}

.input_chatbot{
    flex: 1;
    border: 2px solid #ccc;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.button_chatbot{
    padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #000000;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
    
}

.fix_chatbot{
    position: fixed;
    bottom: 0px;
    right: 0px;
}
.button_colapse_chatbot{
    position: fixed;
    width: 55px;
    height: 55px;
    bottom: 90px;
    right: 30px;
    background: #000000;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    cursor: pointer;
}
/* .robotito{
   margin-top: 4px;
    
} */

.callcenter{
    width: 34px;
}

.button_colapsetrue_chatbot{
    position: absolute;
    right: 1rem;
    top: 1rem;
    /* border-radius: 9999px; */
    background-color: #000000;
    width: 2rem;
    height: 2rem;
    color: white;
}

