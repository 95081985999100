*{
  margin: 0;
  box-sizing: border-box;
  /* white-space: pre-line; */
}
.margintoptodo{
  height: 100px;
}

@font-face {
  font-family: "BankGothic-Regular";   /*Can be any text*/
  src: local("BankGothic-Regular"),
    url("./fonts/BankGothic-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gelion-Black";   /*Can be any text*/
  src: local("Gelion-Black"),
    url("./fonts/Gelion-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Gelion-Bold";   /*Can be any text*/
  src: local("Gelion-Bold"),
    url("./fonts/Gelion-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gelion-Regular";   /*Can be any text*/
  src: local("Gelion-Regular"),
    url("./fonts/Gelion-Regular.ttf") format("truetype");
}

:root{
  --colornavbar : rgb(255, 255, 255);
  --navbarcolora: rgb(0, 0, 0);
  --navbarcolorahover: rgb(150, 175, 188);
  --navbarletra: 'BankGothic-Regular';
  --img__home__about: url("../public/image/home/arrayanes.jpg");
  --BankGothic: 'BankGothic-Regular';
  --GelionBlack: 'Gelion-Black';
  --GelionBold: 'Gelion-Bold';
  --GelionRegular: 'Gelion-Regular';
}


.titulos{
  font-family: var(--GelionBlack)!important;
  font-weight: 700!important;
  color: rgb(19, 19, 19)!important;
  letter-spacing: 1.7px!important;
  line-height: 52px!important;
  font-size: 2rem!important;
}

.parrafos{
  font-family: var(--GelionRegular)!important;
  font-weight: 600!important;
    font-size: 1.125rem!important;
    line-height: 24px!important;
    color: black!important;
    text-decoration: none!important;
}


@media (max-width:768px){
  .titulos{
    padding-left: 35px;
    padding-right: 35px;
  }
  
  .parrafos{
    padding-left: 35px;
    padding-right: 35px;
  }
}