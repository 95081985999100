.navbar{
    background-color: var(--colornavbar);
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 20px 10px;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.logonavbar{
    width: 250px;
    height: 60px;

}
.navizq{
    right: 25px;
}
.imglogonavbar{
    margin-top: 5px;
    width: 200px;
}
#ulnavbar{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--navbarletra);
}
/* .anavbar{
    font-family: var(--navbarletra);
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
} */

.ulnavbarli{
    list-style: none;
    /* padding: 0 0px; */
    position: relative;
}


.ulnavbarlia{
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: var(--navbarcolora);
    transition: 0.3s ease-in-out;
}

.ulnavbarlia:hover{
    color: var(--navbarcolorahover);
    background-color: aliceblue;
    text-decoration: none;
}
.ulnavbarlia:hover::after{
    content: "";
    width: 30%;
    height: 2px;
    background: var(--navbarcolorahover);
    position: absolute;
    bottom: -4px;
    left: 20px;
}
#mobilenavbar{
    display: none;
}
#mobilenavbar i{
    color: var(--navbarcolora);
}

@media screen and (max-width: 769px){
    .ulnavbarlia{
        font-size: 1.3rem;
    }
    #ulnavbar{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 90px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: rgba(220, 218, 218, 0.743);
        padding: 40px 0 0 10px;
        transition: 0.5s ease-in-out;
        z-index: 100;
    }
    #ulnavbar.active{
        right: 0px;
    }
    #ulnavbar li{
        margin-bottom: 25px;
    }
    #mobilenavbar{
        display: block;
    }
    #mobilenavbar i{
        font-size: 24px;
        cursor: pointer;
    }
    .navizq{
        right: 0px;
    }
}

@media screen and (min-width: 1000px){
    .ulnavbarlia{
        font-size: 1.4rem;
    }
}