@import "~react-image-gallery/styles/css/image-gallery.css";

.description__slider{
    font-size: small;
} 

.image-gallery-slide{
    height: 400px;
    /* object-fit: cover!important; */
    
    
} 

.image-gallery-image{
    object-fit: cover!important;
    object-position: center;
    height: 400px;
    
} 

.image-gallery-thumbnails-wrapper{
    top: -60px;
}


@media screen and (min-width: 1024px){
    .description__slider{
        font-size: xx-large;
    } 
    

}