.body__login{
    margin: 0;
    padding: 0;
    font-family: 'poppins',sans-serif;
}

.body__section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: url('https://media.staticontent.com/media/pictures/6a95ecbf-8ab2-4797-87c2-d3ae6e41b07d')no-repeat;
    background-position: center;
    background-size: cover;
}

.form-box__login{
    position: relative;
    width: 400px;
    height: 450px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    /* este es el filtro */
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;

}

.h2__login{
    font-size: 2em;
    color: #fff;
    text-align: center;
}


.inputbox__login{
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}

.inputbox__login label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

.input__login:focus ~ label,
.input__login:valid ~ label{
    top: -5px;
}

.inputbox__login input{
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px;
    color: #fff;
}

.inputbox__login ion-icon{
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

.button__login{
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

