.main__home{
    /* background-color: red; */
    width: 100%;
    display: grid;
    /* max-width: 1400px; */
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-template-areas:
    "main__home"
    "descrip__home"
    "proyectos__home"
    "about__home";
    
}

.main__hero__home{
    grid-area: main__home;
    /* background: url('../../image/home/slices/Bahía_brava_península-min.jpg') */
    /* width: 100%; */
    /* margin-top: 100px; */
}

.main__hero__home img{
    width: 100%;
}
.main__content__home{
    padding: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.main__descrip__home{
    grid-area: descrip__home;
}
.main__about__home{
    grid-area: about__home;
    width: 100%;
    /* min-height: 350px; */
    height: 100%;
    /* background-image: var(--img__home__about); */
    background-size: cover;
    background-position: center;
    object-fit: cover;
    
}

.main__about__home__h2{
    grid-area: title__proyectos__home;
    font-family: var(--navbarletra);
    font-weight: 700;
    color: rgb(9, 9, 9);
    letter-spacing: -1.7px;
    /* line-height: 52px; */
    /* font-size: clamp(1rem, 1.6rem + 6.1vw, 4.5rem); */
    font-size: 3rem;
}

/* .main__about__link__home{
    background-color: black;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    width: fit-content;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 2.25rem;
    transition: background-color 0.2s ease-in 0s;
} */


.main__proyectos__home{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "title__proyectos__home"
        "photo1__proyectos__home"
        "photo2__proyectos__home"
        "photo3__proyectos__home"
        "link__proyectos__home";
    width: 100%;
    overflow: hidden;

}

.main__contenedor__proyectos__home{
    grid-area: photo1__proyectos__home;
    margin-top: 20px;
    position: relative;
    display: inline-block;
    text-align: center;
}
.main__contenedor__proyectos__home:nth-child(2){
    grid-area: photo2__proyectos__home;
}
.main__contenedor__proyectos__home:nth-child(3){
    grid-area: photo3__proyectos__home;
}

.main__titulo__proyecto__home{
    font-weight: 700;
    font-size: 2rem;
    line-height: 40px;
    color: white;
    text-decoration: none;
    margin-bottom: 2.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main__proyectos__home__h2{
    grid-area: title__proyectos__home;
    font-family: var(--navbarletra);
    font-weight: 600!important;
    color: rgb(9, 9, 9);
    /* letter-spacing: -0.7px; */
    line-height: 52px;
    /* font-size: clamp(3rem, 1.6rem + 6.1vw, 4.5rem); */
    font-size: 3rem;
}
.p_titulos_home{
    grid-area: title__proyectos__home;
    font-family: var(--navbarletra)!important;
    font-weight: 600!important;
    color: rgb(9, 9, 9)!important;
    /* letter-spacing: -0.7px; */
    line-height: 52px!important;
    /* font-size: clamp(3rem, 1.6rem + 6.1vw, 4.5rem); */
    font-size: 2.5rem!important;
}
.main__proyectos__link__home{
    margin-top: 20px;
    background-color: black;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 2.25rem;
    transition: background-color 0.2s ease-in 0s;
    grid-area: link__proyectos__home;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}
.fotobienvenidohome{
    display: none;
}

.main__contenedor__proyectos__home img{
    height: 500px;
    object-fit: cover;
}



.imagenes__slices__home{
    height: 400px;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
}
.imagenes__slices__home img{
    height: 400px;
    object-fit: cover;
}
.botonera__slice__home{
    display: block;
    position: relative;
    bottom: 80px;
    /* left: -80px; */
}
.button__slice__home{
    width: 80px;
    height: 80px;
    background-color: white;
    color: rgb(98, 98, 98);
    transition: background-color 0.1s ease-in 0s;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5625rem;
    border: none;
    cursor: pointer;
}
.button__slice__home:hover{
    background-color: rgb(187, 187, 187);
}
.button__slice__home__active{
    background-color: black;
    color: rgb(239, 239, 239);
}

.imagen__proyecto__home{
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(5px);
    transition: 
    filter 200ms linear,
    transform 200ms linear;
    transform: scale(1) translateZ(0);
}

.imagen__proyecto__home:hover{
    transform: scale(1.05) translateZ(0);
    filter: none;
    position: relative;
    z-index: 1;
  }
.imagen__proyecto__home:hover ~ .main__titulo__proyecto__home{
    z-index: 111;
  }



@media screen and (min-width: 1024px){


    .main__descrip__home_g{
        display: grid;
        /* max-width: 1400px; */
        /* height: 700px; */
        margin: 0 auto;
        grid-template-columns: 1fr 2fr;
        /* grid-template-rows: max-content; */
        grid-template-areas:
        "text_home foto_home";
        justify-content: center;
    align-items: center;
    gap: 20px;
    } 

    .tituloyparrafohomebienvenido{
        grid-area: text_home;
        /* display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center; */
        font-size: larger;
    }
    .fotobienvenidohome{
        grid-area: foto_home;
    }



    .fotobienvenidohome{
        display: flex;
    }


    .main__proyectos__home{
        display: grid;

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:  max-content;
        align-items: center;
        text-align: center;
        
        grid-template-areas:
        "title__proyectos__home title__proyectos__home link__proyectos__home"
        "photo1__proyectos__home photo2__proyectos__home photo3__proyectos__home";
        width: 100%;
        overflow: hidden;
    }

    .main__about__home{
        display: grid;

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:  max-content;
        align-items: center;
        text-align: center;
        
        grid-template-areas:
        "title__proyectos__home title__proyectos__home link__proyectos__home"
        "photo1__proyectos__home photo2__proyectos__home photo3__proyectos__home";
        width: 100%;
        overflow: hidden;
    }

    .main__contenedor__proyectos__home img{
        height: 500px;
        object-fit: cover;
    }

    .imagenes__slices__home{
        height: 500px;
    }
    .imagenes__slices__home img{
        height: 500px;
        object-fit: cover;
    }

}