@import "~react-image-gallery/styles/css/image-gallery.css";

.description__slider{
    font-size: small;
} 

.image-gallery-slide{
    height: 400px;
    object-fit: cover!important;
    
    
    
}
/* .slice_proyecto_div{
    width: 90%!important;
} */
/* .image-gallery-swipe{
    
} */

/* .image-gallery-content{
    width: 50vh;
} */
.image-gallery-image{
    
    object-fit: cover!important;
    object-position: center!important;
    height: 400px;
    
} 


@media screen and (min-width: 1024px){
    .description__slider{
        font-size: xx-large;
    } 
    

}