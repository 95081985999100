.footer__footer{
    position: relative;
    isolation: isolate;
    display: block;




    /* display: grid;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "logo__footer"
    "logo1__footer"
    "links__footer"
    "button__footer"; */
}

.footer__container{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 3rem;
}
.footer__container::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 86%;
    background-color: rgb(185, 185, 185);
    z-index: -1;
}

.logo__footer{
    width: 120px;
    background-color: white;
}

.footer__ul{
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    padding-left: 0px;
    gap: 2rem;
}

.links__li__footer{
    font-family: var(--BankGothic);
    list-style: none;
    font-size: 1.125rem;
    line-height: 24px;
    font-weight: 700;
    color: rgb(72, 72, 72);

}

.button__suscribite__footer{
    background-color: rgb(0, 0, 0);
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    width: fit-content;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 2.25rem;
    transition: background-color 0.2s ease-in 0s;
    cursor: pointer;
}


@media screen and (min-width: 1024px){
    /* .footer__footer{
        margin-inline: 100px;
    } */
    .footer__container{
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: 1fr(max-content);
        padding-bottom: 0rem;
        background-color: gray;
    }
    .footer__container::before{
        all: unset;
    }
    .footer__ul{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .links__li__footer{
        font-size: x-large;
        font-weight: 700;
    }
}


