.main__proyecto__proyecto{
  display: grid;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, max-content);
  grid-template-areas: 
  "main_proyecto__proyecto"
  "cta_proyecto__proyecto"
  "image1_proyecto__proyecto"
  "abaout_proyecto__proyecto"
  "image2_proyecto__proyecto";
  margin-bottom: 30px;
}

/* .icono__proyecto__proyecto{
  margin: 0 auto;
  transform: scale(6.5);
  font-weight: 900;
} */

.primera__seccion__proyecto__proyecto{
  grid-area: main_proyecto__proyecto;
  /* min-height: 550px; */
  
}
.container__seccion__proyecto__proyecto{
  padding: 20px;
}

.segunda__seccion__proyecto__proyecto{
  grid-area: cta_proyecto__proyecto;
  
}

.main__content__proyecto__proyecto{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.main__paragraph__proyecto__proyecto{
  line-height: 1.5;
  margin: 1em 0 2em;
}

.main__bg__proyecto__proyecto{
  grid-area: image1_proyecto__proyecto;
}
.main__bg__proyecto__proyecto img{
  width: 100%;
  object-fit: cover!important;
  object-position: center;
  height: 400px;
}

.main__about__proyecto__proyecto{
  grid-area: abaout_proyecto__proyecto;
}
.main__bg__proyecto__proyecto--second{
  grid-area: image2_proyecto__proyecto;
  
}

@media (min-width:768px){
  .main__proyecto__proyecto{
      grid-template-columns: repeat(7,1fr);
      grid-template-areas: 
      "main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto cta_proyecto__proyecto cta_proyecto__proyecto"
      "main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto cta_proyecto__proyecto cta_proyecto__proyecto"
      "main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto main_proyecto__proyecto cta_proyecto__proyecto cta_proyecto__proyecto"
      "image1_proyecto__proyecto image1_proyecto__proyecto abaout_proyecto__proyecto abaout_proyecto__proyecto abaout_proyecto__proyecto image2_proyecto__proyecto image2_proyecto__proyecto"
      "image1_proyecto__proyecto image1_proyecto__proyecto abaout_proyecto__proyecto abaout_proyecto__proyecto abaout_proyecto__proyecto image2_proyecto__proyecto image2_proyecto__proyecto"
      ;
  }
}




















@import url('https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap');
 :root {
	 --d: 700ms;
	 --e: cubic-bezier(0.19, 1, 0.22, 1);
	 --font-sans: 'Rubik', sans-serif;
	 --font-serif: 'Cardo', serif;
}

.body__proyecto__proyecto {
	 height: 100%;
}
.body__proyecto__proyecto {
	 display: grid;
	 place-items: center;
}
 .page-content__proyecto {
	 display: grid;
	 grid-gap: 1rem;
	 padding: 1rem;
	 max-width: 1024px;
	 margin: 0 auto;
	 font-family: var(--BankGothic);
}
 @media (min-width: 600px) {
	 .page-content__proyecto {
		 grid-template-columns: repeat(2, 1fr);
	}
}
 @media (min-width: 800px) {
	 .page-content__proyecto {
		 grid-template-columns: repeat(4, 1fr);
	}
}
 .card__proyecto {
	 position: relative;
	 display: flex;
	 align-items: flex-end;
	 overflow: hidden;
	 padding: 1rem;
	 width: 100%;
	 text-align: center;
	 color: whitesmoke;
	 background-color: whitesmoke;
	 box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
 @media (min-width: 600px) {
	 .card__proyecto {
		 height: 350px;
	}
}
 .card__proyecto:before {
	 content: '';
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 110%;
	 background-size: cover;
	 background-position: 0 0;
	 transition: transform calc(var(--d) * 1.5) var(--e);
	 pointer-events: none;
}
 .card__proyecto:after {
	 content: '';
	 display: block;
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 200%;
	 pointer-events: none;
	 background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.320) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.540) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.790) 100%);
	 transform: translateY(-50%);
	 transition: transform calc(var(--d) * 2) var(--e);
}


 .card__proyecto:nth-child(1):before {
	 background-image: url(../../../public/image/proyectos/foto1.jpg);
}
 .card__proyecto:nth-child(2):before {
	 background-image: url(../../../public/image/proyectos/foto2.jpg);
}
 .card__proyecto:nth-child(3):before {
	 background-image: url(../../../public/image/proyectos/foto3.jpg);
}
 .card__proyecto:nth-child(4):before {
	 background-image: url(../../../public/image/proyectos/foto4.jpg);
}

/* 
 .card__proyecto:nth-child(1):before {
	 background-image: url(https://images.unsplash.com/photo-1517021897933-0e0319cfbc28?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}
 .card__proyecto:nth-child(2):before {
	 background-image: url(https://images.unsplash.com/photo-1533903345306-15d1c30952de?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}
 .card__proyecto:nth-child(3):before {
	 background-image: url(https://images.unsplash.com/photo-1545243424-0ce743321e11?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}
 .card__proyecto:nth-child(4):before {
	 background-image: url(https://images.unsplash.com/photo-1531306728370-e2ebd9d7bb99?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
} */


 .content__proyecto {
	 position: relative;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 width: 100%;
	 padding: 1rem;
	 transition: transform var(--d) var(--e);
	 z-index: 1;
}
 .content__proyecto > * + * {
	 margin-top: 1rem;
}
 .title__proyecto {
	 font-size: 1.3rem;
	 font-weight: bold;
	 line-height: 1.2;
}
 .copy__proyecto {
	 font-family: var(--font-serif);
	 font-size: 1.125rem;
	 font-style: italic;
	 line-height: 1.35;
}
 .btn__proyecto {
	 cursor: pointer;
	 margin-top: 1.5rem;
	 padding: 0.75rem 1.5rem;
	 font-size: 0.65rem;
	 font-weight: bold;
	 letter-spacing: 0.025rem;
	 text-transform: uppercase;
	 color: white;
	 background-color: black;
	 border: none;
}
 .btn__proyecto:hover {
	 background-color: #0d0d0d;
}
 .btn__proyecto:focus {
	 outline: 1px dashed yellow;
	 outline-offset: 3px;
}
 @media (hover: hover) and (min-width: 600px) {
	 .card__proyecto:after {
		 transform: translateY(0);
	}
	 .content__proyecto {
		 transform: translateY(calc(100% - 18rem));
	}
	 .content__proyecto > *:not(.title__proyecto) {
		 opacity: 0;
		 transform: translateY(1rem);
		 transition: transform var(--d) var(--e), opacity var(--d) var(--e);
	}
	 .card__proyecto:hover, .card__proyecto:focus-within {
		 align-items: center;
	}
	 .card__proyecto:hover:before, .card__proyecto:focus-within:before {
		 transform: translateY(-4%);
	}
	 .card__proyecto:hover:after, .card__proyecto:focus-within:after {
		 transform: translateY(-50%);
	}
	 .card__proyecto:hover .content, .card__proyecto:focus-within .content__proyecto {
		 transform: translateY(0);
	}
	 .card__proyecto:hover .content__proyecto > *:not(.title__proyecto), .card__proyecto:focus-within .content__proyecto > *:not(.title__proyecto) {
		 opacity: 1;
		 transform: translateY(0);
		 transition-delay: calc(var(--d) / 8);
	}
	 .card__proyecto:focus-within:before, .card__proyecto:focus-within:after, .card__proyecto:focus-within .content__proyecto, .card__proyecto:focus-within .content__proyecto > *:not(.title__proyecto) {
		 transition-duration: 0s;
	}
}