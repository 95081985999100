@import "~react-image-gallery/styles/css/image-gallery.css";

.description__slider{
    font-size: small;
} 
/* .image-gallery{
    width: 90%;
} */

.image-gallery-slide{
    height: 600px;
    /* object-fit: cover!important; */
    
    
}

.image-gallery-image{
    object-fit: cover!important;
    object-position: center;
    height: 600px;
    
} 



@media screen and (min-width: 1024px){
    .description__slider{
        font-size: xx-large;
    } 
    

}