.main__historia{
    display: grid;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(10, max-content);
    grid-template-areas: 
    "main_historia"
    "cta_historia"
    "main_historia1"
    "cta_historia1"
    "main_historia2"
    "cta_historia2"
    "main_historia3"
    "cta_historia3"
    "main_historia4"
    "cta_historia4"
    "main_historia5"
    "cta_historia5"
    "main_historia6"
    "cta_historia6";
    margin-bottom: 30px;
}


.primera__seccion__historia{
    grid-area: main_historia;
}
.primera__seccion__historia1{
    grid-area: main_historia1;
}
.primera__seccion__historia2{
    grid-area: main_historia2;
}
.primera__seccion__historia3{
    grid-area: main_historia3;
}
.primera__seccion__historia4{
    grid-area: main_historia4;
}
.primera__seccion__historia5{
    grid-area: main_historia5;
}
.primera__seccion__historia6{
    grid-area: main_historia6;
}
.container__seccion__historia{
    padding: 20px;
}

.segunda__seccion__historia{
    grid-area: cta_historia;
    
}
.segunda__seccion__historia1{
    grid-area: cta_historia1;
    
}
.segunda__seccion__historia2{
    grid-area: cta_historia2;
    
}
.segunda__seccion__historia3{
    grid-area: cta_historia3;
    
}
.segunda__seccion__historia4{
    grid-area: cta_historia4;
    
}
.segunda__seccion__historia5{
    grid-area: cta_historia5;
    
}
.segunda__seccion__historia6{
    grid-area: cta_historia6;
    
}

.main__content__historia{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.main__paragraph__historia{
    line-height: 1.5;
    margin: 1em 0 2em;
}

.main__bg__historia img{
    width: 100%;
    object-fit: cover!important;
    object-position: center;
    height: 400px;
}

.divsliceshistoria{
    width: 100vw;
}
/* .react-image-gallery {
    width: 100%!important;
    height: 100%!important;
  }
  .react-image-gallery-slide img {
    width: 100%!important;
  } */

@media (min-width:768px){
    .main__historia{
        grid-template-columns: repeat(7,1fr);
        grid-template-areas: 
        "main_historia main_historia main_historia main_historia main_historia cta_historia cta_historia"
        "cta_historia1 cta_historia1 main_historia1 main_historia1 main_historia1 main_historia1 main_historia1 "
        "main_historia2 main_historia2 main_historia2 main_historia2 main_historia2 cta_historia2 cta_historia2"
        "cta_historia3 cta_historia3 main_historia3 main_historia3 main_historia3 main_historia3 main_historia3 "
        "main_historia4 main_historia4 main_historia4 main_historia4 main_historia4 cta_historia4 cta_historia4"
        "cta_historia5 cta_historia5 main_historia5 main_historia5 main_historia5 main_historia5 main_historia5"
        "main_historia6 main_historia6 main_historia6 main_historia6 main_historia6 cta_historia6 cta_historia6"
        ;
    }
    .divsliceshistoria{
        width: 100%;
    }
    
}

/* 

.main__historia{
    display: grid;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
    grid-template-areas: 
    "main_historia"
    "cta_historia"
    "image1_historia"
    "abaout_historia"
    "image2_historia";
    margin-bottom: 30px;
}

.primera__seccion__historia{
    grid-area: main_historia;
    
}
.container__seccion__historia{
    padding: 20px;
}

.segunda__seccion__historia{
    grid-area: cta_historia;
    
}

.main__content__historia{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.main__paragraph__historia{
    line-height: 1.5;
    margin: 1em 0 2em;
}

.main__bg__historia{
    grid-area: image1_historia;
}
.main__bg__historia img{
    width: 100%;
    object-fit: cover!important;
    object-position: center;
    height: 400px;
}

.main__about__historia{
    grid-area: abaout_historia;
}
.main__bg__historia--second{
    grid-area: image2_historia;
    
}

@media (min-width:768px){
    .main__historia{
        grid-template-columns: repeat(7,1fr);
        grid-template-areas: 
        "main_historia main_historia main_historia main_historia cta_historia cta_historia cta_historia"
        "main_historia main_historia main_historia main_historia cta_historia cta_historia cta_historia"
        "main_historia main_historia main_historia main_historia cta_historia cta_historia cta_historia"
        "image1_historia image1_historia abaout_historia abaout_historia abaout_historia image2_historia image2_historia"
        "image1_historia image1_historia abaout_historia abaout_historia abaout_historia image2_historia image2_historia"
        ;
    }
} */