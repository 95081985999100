.contenedor__proyecto{
    /* padding: 20px; */
    width: 100%;
    /* max-width: 1400px; */
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(10, max-content);
    grid-template-areas: 
    "banner__proyecto"
    "slice__proyecto"
    "primertexto__proyecto"
    "primerafoto__proyecto"
    "segundotexto__proyecto"
    "segundafoto__proyecto"
    "ubicacion__proyecto"
    "map__proyecto"
    "youtube__proyecto"
    "seccion__botones__proyecto"
    ;
    gap: 20px;
    white-space: pre-line;
}

.container__proyecto{
    display: flex;
    align-items: center;
    justify-content: center;
}
.seccion__banner__proyecto{
    grid-area: banner__proyecto;
}
.logobanner__proyecto{
    /* width: 350px; */
    height: 125px;
}

p{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.slices__proyecto{
    grid-area: slice__proyecto;
    /* width: 83%; */
    overflow: hidden;
    padding-right: 5px;
}
.primertexto__proyecto{
    grid-area: primertexto__proyecto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.primerafoto__proyecto img{
    width: 100%;
    object-fit: cover;
    max-height: 500px;
}
.primerafoto__proyecto{
    grid-area: primerafoto__proyecto;

}

.segundotexto__proyecto{
    grid-area: segundotexto__proyecto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    
}
.segundafoto__proyecto{
    grid-area: segundafoto__proyecto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.segundafoto__proyecto img{
    width: 100%;
    max-height: 400px;
    object-fit: cover;
}
.ubicacion__proyecto{
    grid-area: ubicacion__proyecto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.ubicacion__proyecto svg{
    margin-right: 10px;
}
.map__proyecto{
    grid-area: map__proyecto;
    padding-right: 10px;
}
.youtube__proyecto{
    grid-area: youtube__proyecto;
}
.seccion__botones__proyecto{
    grid-area: seccion__botones__proyecto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
}
.boton__proyecto{
    background-color: rgb(0, 0, 0);
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    /* width: fit-content; */
    
    gap: 1.5rem;
    padding: 1.5rem 2.25rem;
    transition: background-color 0.2s ease-in 0s;
    cursor: pointer;

}
.boton__proyecto a{
    color: white;
}


@media (min-width:768px){
    .contenedor__proyecto{
        grid-template-columns: repeat(2,1fr);
        grid-template-areas: 
        "banner__proyecto banner__proyecto"
        "slice__proyecto slice__proyecto"
        "primertexto__proyecto primerafoto__proyecto"
        "segundafoto__proyecto segundotexto__proyecto"
        " ubicacion__proyecto map__proyecto"
        "youtube__proyecto youtube__proyecto"
        "seccion__botones__proyecto seccion__botones__proyecto"
        ;
    }
    .segundafoto__proyecto{
        padding-left: 35px;
    }
    .segundotexto__proyecto{
        padding-right: 35px;
        
    }
    
    .primerafoto__proyecto img{
    padding-right: 35px;
    }
    
    .primertexto__proyecto{
    padding-left: 35px;
    }
    .map__proyecto{
        padding-right: 35px;
    }
}

