.divprinpaneladmin{
    display: flex;
}

.adminpanel{
    padding: 20px;
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 5fr;
}
.logo__paneladmin{
    margin-top: 20px;
    margin-left: 5px;
    width: 95%;
    /* border-bottom: solid black; */
}


.sidbarpaneladmin{
    display: flex;
    flex-direction: column;
    background-color: rgb(123, 123, 123);
    color: white;
    align-items: center;
    gap: 20px
}


.imagen_arriba{
  max-width: 150px;
  /* position: absolute; */
  /* top: 60%; */
  transition: translate(-50%,-50%);

}

.boton__paneladmin{
    min-width: 150px!important;
}

.textarea__proyectoadm{
    width: 100%;
}

.boton_slice_admin{
    /* display: flex; */
    /* position: relative; */
    top: -50%;
    left: 25%;
    font-size: xx-large;
    margin-left: 10px;
}

.file-select {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.file-select::before {
  background-color: #5678EF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: 'Seleccionar'; /* testo por defecto */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 200px;
  height: 32px;
  display: inline-block;
}

#src-file1::before {
  content: 'CAMBIAR LOGO';
}

#foto-1desc::before {
  content: 'CAMBIAR FOTO DESK';
}
#foto-1mob::before {
  content: 'CAMBIAR FOTO MOB';
}
#foto-slices-cambiar::before {
  content: 'CAMBIAR FOTO';
}
#agregarfoto::before {
  content: 'AGREGAR FOTO';
  background-color: green;
}


.guardar-proyecto{
  font-size: 70px;
  padding: 10px;
  border-radius: 15px;
  background-color: rgb(33, 204, 33);
  cursor: pointer;
}







/* :root {
    --white: #e8e9ed;
    --gray: #434257;
    --blue: #18172c;
    --green: #00d084;
    --pink: #ff4b77;
  } */
  
  
  .switches {
    list-style: none;
  }
  
  .label_checkbox_adm {
    cursor: pointer;
  }
  
  .input_checkbox_adm {
    position: absolute;
    left: -9999px;
  }
  
  .switches {
    max-width: 500px;
    width: 95%;
    margin: 50px auto 0;
    border-radius: 5px;
    color: black;
    /* background: var(--blue); */
  }
  
  /* .switches li {
    position: relative;
    counter-increment: switchCounter;
  } */
  
  .switches li:not(:last-child) {
    border-bottom: 1px solid var(--gray);
  }
  
  .switches li::before {
    content: counter(switchCounter);
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    font-size: 2rem;
    font-weight: bold;
    color: var(--pink);
  }
  
  .switches label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  
  .switches span:last-child {
    position: relative;
    width: 50px;
    height: 26px;
    border-radius: 15px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background: var(--gray);
    transition: all 0.3s;
  }
  
  .switches span:last-child::before,
  .switches span:last-child::after {
    content: "";
    position: absolute;
  }
  
  .switches span:last-child::before {
    left: 1px;
    top: 1px;
    width: 24px;
    height: 24px;
    background: var(--white);
    border-radius: 50%;
    z-index: 1;
    transition: transform 0.3s;
  }
  
  .switches span:last-child::after {
    top: 50%;
    right: 8px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
    background-size: 12px 12px;
  }
  
  .switches [type="checkbox"]:checked + label span:last-child {
    background: var(--green);
  }
  
  .switches [type="checkbox"]:checked + label span:last-child::before {
    transform: translateX(24px);
  }
  
  .switches [type="checkbox"]:checked + label span:last-child::after {
    width: 14px;
    height: 14px;
    /*right: auto;*/
    left: 8px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
    background-size: 14px 14px;
  }
  




  .banner__proyecto__adm{
    width: 300px;
  }