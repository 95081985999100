.main__nosotros{
    /* padding: 20px; */
    margin-bottom: 40px;
}

.div__foto__nosotros{
    background-image: url("../../../public/image/nosotros/Espejo_invierno-min.jpg");
    
    height: 450px;
    background-size: cover;
    background-position: center;
}


.h1__texto__nosotros{
    font-family: var(--GelionBold);
    font-weight: 700;
    color: rgb(19, 19, 19);
    letter-spacing: -1.7px;
    line-height: 52px;
    font-size: 2rem;
    margin-bottom: 40px;
    margin-top: 40px;
}
.p__texto__nosotros{
    font-family: var(--GelionRegular);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 24px;
    color: black;
    text-decoration: none;
}

.logos__empresas__nosotros{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
/* .slick-slide{
    background-color: red;
    max-width: 100%!important
} */
/* .divslick{
    background-color: red;
    border-radius: 50px;
} */

.logos__empresas__nosotros img{
    width: 90%;
    aspect-ratio: 1/2;
    object-fit: contain;
}


.div__foto__nosotros2{
    background-image: url("../../../public/image/nosotros/fotonosotros.jpg");

    height: 450px;
    background-size: cover;
    background-position: center;
    
}



@media screen and (min-width: 1024px){
    .seccion1__nosotros{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 200px 200px 200px;
        /* padding: 20px; */
    }
    .div__foto__nosotros{
        grid-column: 1/4;
        grid-row: 1/4;
        height: 100%;
        object-fit: cover;
        
    }
    
    .div__primer__texto__nosotros{
        grid-column: 2/4;
        grid-row: 2/4;
        background-color: rgba(255, 255, 255, 0.601);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .div_primer_texto_nosotros_dos{
        padding: 35px;
    }

    

    .seccion2__nosotros{
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* flex-direction: row; */
        /* align-items: center; */
        /* justify-content: space-between; */
        /* text-align: center; */
        gap: 20px;
        padding-right: 35px;
    }
    .div__foto__nosotros2{
        background-image: url("../../../public/image/nosotros/fotonosotros.jpg");
    
        height: 450px;
        background-size: cover;
        background-position: center;
        
    }

    .logos__empresas__nosotros{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        gap: 15px;
        align-items: center;
        justify-items: center;

    }

    .logos__empresas__nosotros img{
        width: 50%;
        aspect-ratio: 6/2;
        object-fit: contain;
    }


    .div__segundo__texto__nosotros{
        padding: 35px;
    }

    .padding35{
        padding-left: 35px;
    }


}